<style scoped>
.monitor-page {
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}
.v-menu__content {
  box-shadow: none !important;
}
</style>

<template>
  <div class="main">
    <v-navbar v-if="!fullscreen">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="history.length > 1"
            icon
            v-bind="attrs"
            v-on="on"
            @click="goBack"
          >
            <v-icon>mdi-arrow-left-bold-circle-outline</v-icon>
          </v-btn>
        </template>
        <span>返回前一页</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="goHome">
            <v-icon>mdi-home-outline</v-icon>
          </v-btn>
        </template>
        <span>返回主页</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            @click="unConfirmAlarmShow = true"
          >
            <v-badge
              :value="unConfirmAlarmCount"
              :content="unConfirmAlarmCount"
              color="error"
              overlap
            >
              <v-icon>mdi-alert-outline</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <span>未确认告警</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="alarmDetailShow = true">
            <v-icon>mdi-format-list-bulleted-triangle</v-icon>
          </v-btn>
        </template>
        <span>告警列表</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="audioSwitch">
            <v-icon v-if="speakEnable">mdi-account-voice</v-icon>
            <v-icon v-else>mdi-voice-off</v-icon>
          </v-btn>
        </template>
        <span v-if="speakEnable">关闭AI语音播报</span>
        <span v-else>开启AI语音播报</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="showAlarm = !showAlarm">
            <v-icon v-if="showAlarm">mdi-playlist-check</v-icon>
            <v-icon v-else>mdi-playlist-remove</v-icon>
          </v-btn>
        </template>
        <span v-if="showAlarm">关闭告警字幕</span>
        <span v-else>开启告警字幕</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="inspection.show = true">
            <v-icon>mdi-file-restore-outline</v-icon>
          </v-btn>
        </template>
        <span>页面轮询</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" class="fillMode">
            <v-icon>mdi-aspect-ratio</v-icon>
          </v-btn>
          <v-menu
            activator=".fillMode"
            offset-x
            offset-y
            left
            bottom
            rounded
            min-width="100"
          >
            <v-list>
              <v-list-item
                v-for="item in fillModes"
                :key="item.value"
                @click="fillMode = item.value"
              >
                <v-list-item-title
                  v-text="item.text"
                  :style="{
                    color: fillMode == item.value ? '#007BFF' : 'black',
                  }"
                ></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <span>拉伸方式</span>
      </v-tooltip>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="setFullscreen(true)">
            <v-icon>mdi-fullscreen</v-icon>
          </v-btn>
        </template>
        <span>全屏浏览</span>
      </v-tooltip>
    </v-navbar>
    <div class="monitor-page" ref="container" v-resize="onResize">
      <zht-component
        :control="control"
        :pageId="pageId"
        :isPage="true"
        :isView="true"
        :rootWidth="rootWidth"
        :rootHeight="rootHeight"
        :fillMode="fillMode"
      ></zht-component>
    </div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-show="fullscreen"
          v-bind="attrs"
          v-on="on"
          fab
          small
          right
          absolute
          elevation="0"
          color="rgba(128,128,128,0.2)"
          class="white--text ma-3"
        >
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </template>
      <div class="d-flex flex-column">
        <v-btn
          v-show="history.length > 1"
          fab
          small
          elevation="0"
          color="rgba(255,255,255,0.8)"
          class="mt-1"
          @click="goBack"
        >
          <v-icon>mdi-arrow-left-bold-circle-outline</v-icon>
        </v-btn>
        <v-btn
          fab
          small
          elevation="0"
          color="rgba(255,255,255,0.8)"
          class="mt-1"
          @click="goHome"
        >
          <v-icon>mdi-home</v-icon>
        </v-btn>
        <v-btn
          fab
          small
          elevation="0"
          color="rgba(255,255,255,0.8)"
          class="mt-1"
          @click="unConfirmAlarmShow = true"
        >
          <v-icon>mdi-alert</v-icon>
        </v-btn>
        <v-btn
          fab
          small
          elevation="0"
          color="rgba(255,255,255,0.8)"
          class="mt-1"
          @click="alarmDetailShow = true"
        >
          <v-icon>mdi-format-list-bulleted-triangle</v-icon>
        </v-btn>
        <v-btn
          fab
          small
          elevation="0"
          color="rgba(255,255,255,0.8)"
          class="mt-1"
          @click="speakEnable = !speakEnable"
        >
          <v-icon v-if="speakEnable">mdi-account-voice</v-icon>
          <v-icon v-else>mdi-voice-off</v-icon>
        </v-btn>
        <v-btn
          fab
          small
          elevation="0"
          color="rgba(255,255,255,0.8)"
          class="mt-1"
          @click="showAlarm = !showAlarm"
        >
          <v-icon v-if="showAlarm">mdi-playlist-check</v-icon>
          <v-icon v-else>mdi-playlist-remove</v-icon>
        </v-btn>
        <v-btn
          fab
          small
          elevation="0"
          color="rgba(255,255,255,0.8)"
          class="mt-1"
          @click="inspection.show = true"
        >
          <v-icon>mdi-file-restore-outline</v-icon>
        </v-btn>
        <v-btn
          fab
          small
          elevation="0"
          color="rgba(255,255,255,0.8)"
          class="mt-1"
          @click="setFullscreen(false)"
        >
          <v-icon>mdi-fullscreen-exit</v-icon>
        </v-btn>
      </div>
    </v-menu>
    <AlarmDetailDialog v-model="alarmDetailShow"></AlarmDetailDialog>
    <UnConfirmAlarmDialog v-model="unConfirmAlarmShow"></UnConfirmAlarmDialog>
    <v-dialog v-model="inspection.show" persistent max-width="400">
      <v-card>
        <v-card-title>
          <span>页面轮询</span>
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="inspection.addShow = true"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>添加轮询页面</span>
          </v-tooltip>
          <v-btn icon @click="inspection.show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-data-table
          dense
          :headers="[
            { text: '名称', value: 'name' },
            { text: '操作', value: 'actions', align: 'right' },
          ]"
          :items="inspection.pages"
          hide-default-footer
          disable-pagination
          style="height: 360px; overflow-y: auto"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="inspectionUp(item)">
              <v-icon>mdi-arrow-up</v-icon>
            </v-btn>
            <v-btn icon @click="inspectionDown(item)">
              <v-icon>mdi-arrow-down</v-icon>
            </v-btn>
            <v-btn icon @click="inspectionRemove(item)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <v-divider></v-divider>
        <v-card-actions>
          <span>轮询状态：{{ inspection.enable ? "已启动" : "已停止" }}</span>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              inspection.enable = !inspection.enable;
              inspection.show = false;
            "
          >
            {{ inspection.enable ? "停止轮询" : "启动轮询" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ObjectSelector
      v-model="inspection.addShow"
      type="page"
      :single="false"
      @add="inspectionAdd"
    ></ObjectSelector>
    <VoiceDialog v-model="voiceShow" @voice="audioStart"></VoiceDialog>
    <audio
      ref="audio"
      style="display: none"
      @ended="audioEnd"
      @error="audioError"
    ></audio>
  </div>
</template>

<script>
import base64 from "../utils/base64";
import RES from "../utils/res";
import proto from "../utils/proto";
import client from "../utils/client";
import store from "../utils/store";
import AlarmDetailDialog from "../components/AlarmDetailDialog.vue";
import UnConfirmAlarmDialog from "../components/UnConfirmAlarmDialog.vue";
import ObjectSelector from "../components/ObjectSelector.vue";
import VoiceDialog from "../components/VoiceDialog.vue";

export default {
  components: {
    AlarmDetailDialog,
    UnConfirmAlarmDialog,
    ObjectSelector,
    VoiceDialog,
  },
  data() {
    return {
      pageId: null,
      history: [],
      rootWidth: 0,
      rootHeight: 0,
      fillMode: 1,
      fillModes: [
        { text: "原始尺寸", value: 0 },
        { text: "水平拉伸", value: 1 },
        { text: "全屏拉伸", value: 2 },
      ],
      showAlarm: true,
      alarmDetailShow: false,
      unConfirmAlarmShow: false,
      inspection: {
        timer: null,
        show: false,
        enable: false,
        pages: [],
        addShow: false,
        index: 0,
      },
      voiceShow: false,
      speakEnable: false,
      speakOnce: true,
      speakList: [],
      speakUrl: "",
    };
  },
  computed: {
    control() {
      return { style: {}, config: { pageId: this.pageId } };
    },
    fullscreen() {
      return client.fullscreen;
    },
    unConfirmAlarmCount() {
      return client.statistics.s8;
    },
  },
  watch: {
    speakEnable(value) {
      store.set("speakEnable", value ? "true" : "false");
      if (value) {
        this.startSpeak();
      }
    },
    fillMode(value) {
      store.set("fillMode", value);
    },
    showAlarm(value) {
      store.set("showAlarm", value ? "true" : "false");
    },
    fullscreen() {
      setTimeout(this.onResize, 1);
    },
    "inspection.enable": {
      handler() {
        this.inspectionChange();
      },
    },
  },
  created() {
    this.speakEnable = store.get("speakEnable") == "true";
    this.fillMode = parseInt(store.get("fillMode") || "1");
    this.showAlarm = store.get("showAlarm") == "true";
    this.inspection.enable = store.get("inspectionEnable") == "true";
    try {
      this.inspection.pages = JSON.parse(
        base64.decode(store.get("inspectionPages"))
      );
    } catch (e) {
      this.inspection.pages = [];
    }
    client.$on("spot-ctrl", this.spotControl);
    client.$on("navigate", this.navigate);
    client.$on("orgChange", this.goHome);
    client.$on("brocast", this.brocast);
    this.goHome();
  },
  mounted() {
    this.audioUrl();
    this.onResize();
    this.startEvent();
    this.inspectionChange();
  },
  beforeDestroy() {
    if (this.inspection.timer) clearInterval(this.inspection.timer);
    this.inspection.timer = null;
    client.$off("spot-ctrl", this.spotControl);
    client.$off("navigate", this.navigate);
    client.$off("orgChange", this.goHome);
    client.$off("brocast", this.brocast);
    RES.clear();
  },
  methods: {
    /** page action */
    async spotControl(action) {
      if (!action.spot.uuid) return;
      if (action.srcType != "const") return;
      let value = action.value;
      client.$emit(
        "startBusy",
        "MonitorPage.spotControl",
        "正在控制测点,请稍候..."
      );
      await proto.sleep(100);
      try {
        await client.send(proto.MESSAGE_TYPE.realTimeDataMessage, {
          mcd: {
            operate: proto.OperateMode.updateOpt,
            range: action.spot.uuid,
          },
          rtdata: [
            {
              uuid: action.spot.uuid,
              sv: {
                valueData: base64.encode(value),
              },
            },
          ],
        });
        client.$emit("toast", "控制成功", "success");
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "MonitorPage.spotControl");
    },
    navigate(action) {
      if (action.page.uuid) {
        this.gotoPage(action.page.uuid);
      }
    },
    /** server methods */
    async goHome() {
      client.$emit("startBusy", "MonitorPage.goHome", "正在获取主页,请稍候...");
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.organizationMessage, {
          mcd: {
            operate: proto.OperateMode.retrieveOpt,
            range: "-1",
          },
          orgs: [{ id: client.org.id }],
        });
        if (res.orgs.length) {
          this.gotoPage(res.orgs[0].homepage);
        } else {
          client.$emit("toast", "此机构没有设置主页");
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "MonitorPage.goHome");
    },
    async startEvent() {
      try {
        await client.send(proto.MESSAGE_TYPE.realTimeDataMessage, {
          mcd: {
            operate: proto.OperateMode.createOpt,
          },
          rtdata: [],
        });
      } catch (error) {
        client.$emit("toast", error);
      }
    },
    gotoPage(pageId) {
      if (pageId != this.pageId) {
        this.pageId = pageId;
        this.history.push(this.pageId);
        if (this.history.length > 11)
          this.history.splice(0, this.history.length - 11);
      }
    },
    goBack() {
      if (this.history.length > 1) {
        let pageId = this.history[this.history.length - 2];
        this.history.splice(this.history.length - 1, 1);
        this.pageId = pageId;
      } else {
        client.$emit("toast", "没有更多的浏览历史");
      }
    },
    onResize() {
      this.rootWidth = this.$refs.container.clientWidth;
      this.rootHeight = this.$refs.container.clientHeight;
    },
    brocast(type, msg) {
      switch (type) {
        case proto.MESSAGE_TYPE.realTimeEventMessage:
          if (msg.rtevent) {
            for (let i in msg.rtevent) {
              let event = msg.rtevent[i];
              if (event.description) {
                if (event.et != proto.EVENT_TYPE.sys_event) {
                  this.speakList.unshift(event.description);
                  while (this.speakList.length > 5) this.speakList.pop();
                  if (this.showAlarm) {
                    client.$emit(
                      "alarm",
                      `[${event.level}] ${event.description}`,
                      event.level ? "error" : "success"
                    );
                  }
                }
              }
            }
          }
          this.startSpeak();
          break;
      }
    },
    setFullscreen(value) {
      client.fullscreen = value;
    },
    /** inspection */
    inspectionAdd(page) {
      for (let i in this.inspection.pages) {
        if (this.inspection.pages[i].id == page.id) return;
      }
      this.inspection.pages.push(page);
      store.set(
        "inspectionPages",
        base64.encode(JSON.stringify(this.inspection.pages))
      );
    },
    inspectionRemove(page) {
      let index = this.inspection.pages.indexOf(page);
      if (index != -1) this.inspection.pages.splice(index, 1);
      store.set(
        "inspectionPages",
        base64.encode(JSON.stringify(this.inspection.pages))
      );
    },
    inspectionUp(page) {
      let index = this.inspection.pages.indexOf(page);
      if (index > 0) {
        this.inspection.pages.splice(index, 1);
        this.inspection.pages.splice(index - 1, 0, page);
      }
      store.set(
        "inspectionPages",
        base64.encode(JSON.stringify(this.inspection.pages))
      );
    },
    inspectionDown(page) {
      let index = this.inspection.pages.indexOf(page);
      if (index != -1 && index < this.inspection.pages.length - 1) {
        this.inspection.pages.splice(index, 1);
        this.inspection.pages.splice(index + 1, 0, page);
      }
      store.set(
        "inspectionPages",
        base64.encode(JSON.stringify(this.inspection.pages))
      );
    },
    inspectionChange() {
      store.set("inspectionEnable", this.inspection.enable ? "true" : "false");
      if (this.inspection.timer) clearInterval(this.inspection.timer);
      if (this.inspection.enable) {
        this.inspection.index = 0;
        this.inspection.timer = setInterval(this.inspectionWork, 10000);
      } else {
        this.inspection.timer = null;
      }
    },
    inspectionWork() {
      let current = this.inspection.index;
      let total = this.inspection.pages.length;
      if (total) {
        current = current % total;
        let page = this.inspection.pages[current];
        this.inspection.index = current + 1;
        this.gotoPage(page.id);
      }
    },
    /** speak */
    audioSwitch() {
      if (this.speakEnable == true) {
        this.speakEnable = false;
      } else {
        this.voiceShow = true;
      }
    },
    audioStart() {
      this.audioUrl();
      this.speakList.push("语音播报已开启");
      this.speakEnable = true;
    },
    audioUrl() {
      let id = store.get("voice") || "zh-CN-YunyangNeural";
      let rate = parseFloat(store.get("rate") || "1");
      let pitch = parseFloat(store.get("pitch") || "1");
      this.speakUrl = `//tts.szzht.com/tts?id=${id}&rate=${rate}&pitch=${pitch}&text=$word`;
    },
    audioEnd() {
      this.startSpeak();
    },
    audioError() {
      this.speakOnce = true;
      client.$emit("toast", "当前网络无法进行语音播报");
      this.startSpeak();
    },
    startSpeak() {
      if (this.speakEnable && this.speakUrl) {
        if (this.$refs.audio) {
          if (this.$refs.audio.ended || this.speakOnce) {
            if (this.speakList.length > 0) {
              let words = this.speakList.pop();
              this.speakOnce = false;
              this.$refs.audio.src = this.speakUrl.replace(
                "$word",
                encodeURIComponent(words)
              );
              try {
                this.$refs.audio.play();
              } catch (e) {
                console.log(e);
              }
            }
          }
        }
      }
    },
  },
};
</script>
