import MonitorPage from './MonitorPage.vue';
import MonitorDevice from './MonitorDevice.vue';
import ConfigPage from './ConfigPage.vue';
import ConfigUser from './ConfigUser.vue';
import ConfigPeriod from './ConfigPeriod.vue';
import ConfigReceive from './ConfigReceive.vue';
import ConfigDevice from './ConfigDevice.vue';
import ConfigResource from './ConfigResource.vue';
import ConfigPolicy from './ConfigPolicy.vue';
import HistoryData from './HistoryData.vue';
import HistoryEvent from './HistoryEvent.vue';
import HistoryLog from "./HistoryLog.vue";
import SystemStorage from "./SystemStorage.vue";

const views = [
  { name: 'monitor', title: '监控' },
  { name: 'monitor-page', title: '页面监控', icon: 'mdi-monitor-dashboard', component: MonitorPage },
  { name: 'monitor-device', title: '设备监控', icon: 'mdi-layers-search', component: MonitorDevice },
  { name: 'config-3', title: '配置-页面' },
  { name: 'config-page', title: '页面组态', icon: 'mdi-monitor-edit', component: ConfigPage },
  { name: 'config-resource', title: '资源配置', icon: 'mdi-folder-multiple-image', component: ConfigResource },
  { name: 'config-2', title: '配置-设备' },
  { name: 'config-device', title: '设备组态', icon: 'mdi-lan-connect', component: ConfigDevice },
  { name: 'config-policy', title: '策略配置', icon: 'mdi-brain', component: ConfigPolicy },
  { name: 'config-1', title: '配置-用户' },
  { name: 'config-user', title: '用户管理', icon: 'mdi-account-multiple', component: ConfigUser },
  { name: 'config-receive', title: '接警组管理', icon: 'mdi-account-group', component: ConfigReceive },
  { name: 'config-period', title: '接警时段管理', icon: 'mdi-timetable', component: ConfigPeriod },
  { name: 'history', title: '记录' },
  { name: 'history-data', title: '历史数据', icon: 'mdi-calendar-text', component: HistoryData },
  { name: 'history-event', title: '历史事件', icon: 'mdi-calendar-alert', component: HistoryEvent },
  { name: 'history-log', title: '操作日志', icon: 'mdi-calendar-account', component: HistoryLog },
  { name: 'system', title: '系统' },
  { name: 'system-storage', title: '存储配置', icon: 'mdi-database-cog', component: SystemStorage },
];

export default {
  views,
  install: function (Vue) {
    for (let i in views) {
      let view = views[i];
      if (view.name && view.component) Vue.component(view.name, view.component);
    }
  },
};
